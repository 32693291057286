import React, { useEffect, useState } from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import qs from 'query-string'
import {
  redirectWithToken,
  getTenantConfig,
  getOauth2Client,
  loginWithOpenidProvider,
} from '../services/auth'
import { buildEmail, getErrorFromCode, getTenant } from '../util'
import '../styles/main.css'

const FirebaseAuth = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState()
  const [isLoading, setLoading] = useState(false)
  const [openIdClient, setOpenIdClient] = useState()
  const [currentTenant, setCurrentTenant] = useState()
  const [infoMessage, setInfoMessage] = useState()
  const [isDisabled, setIsDisabled] = useState(true)

  useEffect(() => {
    (async () => {
      const tenant = getTenant()
      const { authProviders } = await getTenantConfig(tenant)
      setCurrentTenant(tenant)
      const isDefaultEnabled = !authProviders || !authProviders.length || authProviders.includes('default')
      const isOpenidEnabled = authProviders && authProviders.includes('openid')
      if (isOpenidEnabled) {
        const oauthClient = await getOauth2Client(tenant)
        if (!isDefaultEnabled) await loginWithOpenidProvider()
        else setOpenIdClient(oauthClient)
      }
    })()
    const unsuccessfulLogin = window.location.href.indexOf('unsuccessfulLogin') > -1
    if ( unsuccessfulLogin ) setErrorMessage('You don’t have a valid account on this site. Please contact your site administrator if this is an issue')
    const { error } = qs.parse(window.location.search)
    if (error) setErrorMessage(getErrorFromCode(error))
  }, [])

  useEffect(() => {
    const { emailReset } = qs.parse(window.location.search)
    if (isLoading) { setInfoMessage('Loading...') }
    else if (emailReset) { setInfoMessage('You have successfully reset your password, please log in with your new details') }
    else { setInfoMessage('Please sign in') }
  }, [isLoading])

  useEffect(() => {
    setIsDisabled(!firebase.apps.length || !password.length || !email.length)
  }, [firebase.apps, password, email])

  const handleError = error => {
    switch (error.code) {
      case 'auth/invalid-email':
      case 'auth/user-not-found':
      case 'auth/wrong-password':
        setErrorMessage(getErrorFromCode('LOGIN-001'))
        break
      case 'auth/too-many-requests':
        setErrorMessage(getErrorFromCode('LOGIN-002'))
        break
      case 'auth/user-disabled':
        setErrorMessage(getErrorFromCode('SESS-002'))
        break
      default:
        setErrorMessage(getErrorFromCode())
        break
    }
    setLoading(false)
  }
  const loginWithEmailAndPassword = () => {
    // if the user input is not an email, build an email based on configuration
    const actualEmail = buildEmail(email, currentTenant)
    try {
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(() => firebase.auth().signInWithEmailAndPassword(actualEmail, password))
        .then(() => firebase.auth().currentUser.getIdToken())
        .then(redirectWithToken)
        .catch(handleError)
    } catch (error) {
      // Fallback for IE 11 InPrivate browsing
      console.error(error)
      firebase.auth().signInWithEmailAndPassword(email, password)
        .then(() => firebase.auth().currentUser.getIdToken())
        .then(redirectWithToken)
        .catch(handleError)
    }
  }
  const handleSubmit = async (event, provider) => {
    event.preventDefault()
    event.nativeEvent.stopImmediatePropagation()
    setErrorMessage(null)
    setEmail('')
    setPassword('')
    setLoading(true)
    switch (provider) {
      case 'openid':
        await loginWithOpenidProvider()
        break;
      default:
        loginWithEmailAndPassword()
    }
  }
  return (
    <form action="#" autoComplete="on" className="formStyles" onSubmit={handleSubmit}>
      <div className="signInStyles">
        {infoMessage}
        {errorMessage && <p className="linkStyles" id="error_box">{errorMessage}</p>}
      </div>
      <div className="loader" hidden={!isLoading}></div>
      <input
        className="inputStyles"
        name="email"
        value={email}
        onChange={e => setEmail(e.target.value)}
        type="text"
        placeholder="Username / email"
        autoComplete="email"
        hidden={isLoading}
        required
      />
      <input
        className="inputStyles"
        name="password"
        value={password}
        onChange={e => setPassword(e.target.value)}
        type="password"
        placeholder="Password"
        autoComplete="current-password"
        hidden={isLoading}
        required
      />
      <button name="login" className="buttonStyles" disabled={isDisabled} type="submit" hidden={isLoading}>
        Login
      </button>
      {
        !!openIdClient &&
        <button
          name="openid-login"
          className="buttonStyles"
          hidden={isLoading}
          onClick={e => handleSubmit(e, 'openid')}
        >
          Login with Corporate Account
        </button>
      }
    </form>
  )
}

export const passwordReset = (emailAddress) => {
  firebase.auth().sendPasswordResetEmail(emailAddress).then(() => {
  }).catch((error) => {
    const err = error
    console.log('ERROR: ', err)
  })
  return alert(
    'If this is a valid account address, you will receive an email with details on how to reset your password')
}

export default FirebaseAuth

