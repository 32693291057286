// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-callback-js": () => import("./../src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-error-page-js": () => import("./../src/pages/errorPage.js" /* webpackChunkName: "component---src-pages-error-page-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-logout-js": () => import("./../src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-reset-password-js": () => import("./../src/pages/resetPassword.js" /* webpackChunkName: "component---src-pages-reset-password-js" */)
}

