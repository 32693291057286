import { useEffect } from 'react'
import { checkSession } from '../services/auth'

export default () => {
  useEffect(() => {
    (async () => {
      await checkSession()
    })()
  }, [])
  return null // TODO: show a splash screen?
}
