import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import qs from 'query-string'
import { resetPassword } from '../services/auth'
import concreteLogo from '../assets/concreteLogo.png'
import Logout from '../components/Logout'
import { buildEmail, getTenant } from '../util'

export default  () => {
  const logoRef = useRef()
  const [brandingUrl, setBrandingUrl] = useState()
  const [brandingDomain, setBrandingDomain] = useState('undefined')
  const [currentTenant, setCurrentTenant] = useState()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState()
  const [isLoading, setLoading] = useState(false)
  const [infoMessage, setInfoMessage] = useState()
  const [isDisabled, setIsDisabled] = useState(true)

  useEffect(() => {
    const isDev = window.location.hostname.includes('devconcretelogin')
    setBrandingUrl(`https://${isDev ? 'dev-' : ''}branding.retailcloud.net/`)
    const tenant = getTenant()
    setCurrentTenant(tenant)
    setBrandingDomain(tenant || 'undefined')
  }, [])

  useEffect(() => {
    if (newPassword !== confirmPassword) {
      setErrorMessage('New password and confirm password do not match')
    } else {
      setErrorMessage(null)
    }
    if (!!newPassword.length && newPassword.length < 6) setErrorMessage('Password has to be a minimum of 6 characters')
  }, [newPassword, confirmPassword])

  useEffect(() => {
    setIsDisabled(!password.length || !email.length || !newPassword.length || !confirmPassword.length || !!errorMessage)
  }, [password, email, confirmPassword, newPassword, errorMessage])

  useEffect(() => {
    if (isLoading) { setInfoMessage('Loading...') }
    else if (email.length && password.length) { setInfoMessage('And now enter your new password to fully secure your account') }
    else { setInfoMessage('Your password has expired, please begin by re-entering your current details') }
  }, [isLoading, email, password])

  const handleConcreteClick = () => window.open(process.env.GATSBY_CONCRETE_URL)
  const handleSubmit = async (event) => {
    event.preventDefault()
    event.nativeEvent.stopImmediatePropagation()
    setErrorMessage(null)
    setLoading(true)

    try {
      const actualEmail = buildEmail(email, currentTenant)
      await resetPassword(actualEmail, password, newPassword)
      const queryParams = qs.parse(window.location.search)
      const queryString = `?${qs.stringify({ ...queryParams, referrer: document.referrer, emailReset: true })}`
      const path = `https://${window.location.host}/login${queryString}`
      window.location.replace(path)
    } catch (e) {
      console.error('reset password error: ', e)
      const errorMessage = (e.message === 'Unauthorized') ? 'Please check your username/email and password' : 'An error occurred'
      setErrorMessage(errorMessage)
      setLoading(false)
    }
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex"/>
      </Helmet>
      {brandingUrl && <link rel="stylesheet" type="text/css" href={`${brandingUrl}${brandingDomain}/branding.css`}/>}
      <div className="reset-password-container">
        <Logout errorCode="PWD-RESET" showContentOnError={true}>
          <div className="loginContainer">
            <div className="LogoContainer">
              {brandingUrl && <img ref={logoRef} src={`${brandingUrl}${brandingDomain}/logo.png`} onError={() => { logoRef.current.src = concreteLogo }} alt="Brand Logo" />}
            </div>
            <form action="#" autoComplete="on" className="formStyles" onSubmit={handleSubmit}>
              <div className="signInStyles">
                {infoMessage}
                {errorMessage && <p className="linkStyles" id="error_box">{errorMessage}</p>}
              </div>
              <div className="loader" hidden={!isLoading}></div>
              <input
                className="inputStyles"
                name="email"
                value={email}
                onChange={e => {
                  setEmail(e.target.value)
                  setErrorMessage(null)
                }}
                type="text"
                placeholder="Username / email"
                autoComplete="email"
                hidden={isLoading}
                required
              />
              <input
                className="inputStyles"
                name="password"
                value={password}
                onChange={e => {
                  setPassword(e.target.value)
                  setErrorMessage(null)
                }}
                type="password"
                placeholder="Password"
                autoComplete="current-password"
                hidden={isLoading}
                required
              />
              <input
                className="inputStyles"
                name="new_password"
                value={newPassword}
                onChange={e => setNewPassword(e.target.value)}
                type="password"
                placeholder="New Password"
                autoComplete="new-password"
                hidden={isLoading}
                required
              />
              <input
                className="inputStyles"
                name="confirm_password"
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
                type="password"
                placeholder="Confirm New Password"
                autoComplete="new-password"
                hidden={isLoading}
                required
              />
              <button name="login" className="buttonStyles" disabled={isDisabled} type="submit" hidden={isLoading}>
                Reset password
              </button>
            </form>
            <div className="concreteContainer" onClick={handleConcreteClick}>
              <p className="poweredBy">
                <span className="poweredBySpan">Powered by </span>
              </p>
              <img className="concreteImage" src={`${brandingUrl}concrete/logo-main-default.png`} alt="Concrete Logo"/>
            </div>
          </div>
          <div className="imagePosition" style={{ backgroundImage: `url(${brandingUrl}${brandingDomain}/background.jpg)` }}></div>
        </Logout>
      </div>
    </>
  )
}
