import { useEffect } from 'react'
import jwt from 'jsonwebtoken'
import firebase from 'firebase/app'
import 'firebase/auth'
import {
  redirectWithToken,
  getTenantConfig,
  getOauth2Client,
  verifyToken,
  getPKCECodeVerifier,
} from '../services/auth'
import { getTenant } from '../util'

export default () => {
  useEffect(() => {
    (async () => {
      try {
        const tenant = getTenant()
        const config = await getTenantConfig(tenant)
        const { authProviders, idClaim, oauthResponseType = 'id_token' } = config
        if (!authProviders || !authProviders.includes('openid')) {
          window.location.replace(`/login?error=SSO-001`)
        }
        const client = await getOauth2Client(tenant)
        const flowType = oauthResponseType.includes('code') && !oauthResponseType.includes('id_token')
          ? 'code'
          : 'token'
        // TODO: might need changes to support code flow for specific IdPs
        const uri =  window.location
        const user = await client[flowType].getToken(uri, {
          body: {
            code_verifier: getPKCECodeVerifier(), 
          },
        })
        const ssoToken = user.data['id_token']
        if (ssoToken) {
          const { exp, iat } = jwt.decode(ssoToken)
          const token = await verifyToken(ssoToken, idClaim)
          await firebase.auth().signInWithCustomToken(token)
          const idToken = await firebase.auth().currentUser.getIdToken()
          localStorage.setItem('sso-token', ssoToken)
          localStorage.setItem('session-expiry-timeout', exp - iat)
          redirectWithToken(idToken)
        } else {
          window.location.replace(`/login?error=SSO-002`)
        }
      } catch (error) {
        console.log('Error', error)
        window.location.replace(`/errorPage?error=SSO-003`)
      }
    })()
  })
  return null
}