import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { saveCookie } from '../services/auth'
import concreteLogo from '../assets/concreteLogo.png'
import FirebaseAuth, { passwordReset } from '../components/FirebaseAuth'
import { getTenant } from '../util'
import tenants from '../tenants.json'

export default  () => {
  const logoRef = useRef()
  const [brandingUrl, setBrandingUrl] = useState()
  const [brandingDomain, setBrandingDomain] = useState('undefined')
  const [subdomain, setSubdomain] = useState('one')

  useEffect(() => {
    const isDev = window.location.hostname.includes('devconcretelogin')
    setBrandingUrl(`https://${isDev ? 'dev-' : ''}branding.retailcloud.net/`)
    // we get the tenant from the host or from a previously stored cookie if no subdomain is set (one case)
    const tenant = getTenant()
    if (tenant) {
      // if we're coming from a branded website
      // we'll remember the current brand
      saveCookie(process.env.GATSBY_COOKIE_TENANT, tenant, { expires: 365 })
      setSubdomain(tenant)
    }
    // TODO: we should probably change the folder name for default branding on the bucket that stores the branding styles
    setBrandingDomain(tenant || 'undefined')
  }, [])

  const handleConcreteClick = () => window.open(process.env.GATSBY_QUINYX_URL)
  // TODO: there is no T&Cs page for one?
  const handleTnCsClick = () =>
    window.open(`https://${subdomain}.retailcloud.net/termsAndConditions.cfm?displayOnly=true`)
  const handleResetPassword = () => {
    let email = prompt('Please enter your email address')

    if (email !== null || email !== '') {
      passwordReset(email)
    }
  }

  const registrationEnabled = tenants.registrationEnabled.includes(subdomain)
  
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex"/>
      </Helmet>
      {brandingUrl && <link rel="stylesheet" type="text/css" href={`${brandingUrl}${brandingDomain}/branding.css`}/>}
      <div className="loginContainer">
        <div className="LogoContainer">
          {brandingUrl && <img ref={logoRef} src={`${brandingUrl}${brandingDomain}/logo.png`} onError={() => { logoRef.current.src = concreteLogo }} alt="Brand Logo" />}
        </div>
        <FirebaseAuth />
        <div className="linkStyles" id="linkContainer">
          <div className="clickableContainer">
            <div className="clickable" onClick={handleResetPassword}>
              Forgotten your password?
            </div>
          </div>
          {registrationEnabled &&
            <div className="clickableContainerRegistration">
              <a href={`https://${subdomain}.retailcloud.net/registration_form.cfm`} className="clickable linkStyles">
                New to this site? Register here
              </a>
            </div>
          }
          <div className="clickableContainer">
            View <div className="clickable" onClick={handleTnCsClick}>Terms & Conditions</div>
          </div>
        </div>
        <div className="concreteContainer" onClick={handleConcreteClick}>
          <p className="poweredBy">
            <span className="poweredBySpan">Powered by</span>
          </p>
          <img className="concreteImage" src={`${brandingUrl}concrete/logo-main-default.png`} alt="Concrete Logo"/>
        </div>
      </div>
      <div className="imagePosition" style={{ backgroundImage: `url(${brandingUrl}${brandingDomain}/background.jpg)` }}></div>
    </>
  )
}
