import 'url-polyfill'
import 'whatwg-fetch'
import 'text-encoding'
import qs from 'query-string'
import firebase from 'firebase/app'
import 'firebase/auth'
import { firebaseConfig } from './firebaseConfig'
import './src/styles/main.css'

const onClientEntry = () => {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig)
  }

  if(window.location.hostname !== 'localhost' && window.location.protocol !== "https:") {
    const queryParams = qs.parse(window.location.search)
    // referrer could be the login page itself if a user manually edits the url by replacing https with http (or otherwise alters/refreshes the url)
    // services/auth redirectToken deals with that by always redirecting to https and defaulting to one or one-dev
    // in case the referrer does not represents the caller anymore because of internal redirects
    const queryString = `?${qs.stringify({ ...queryParams, referrer: document.referrer })}`
    const path = `https://${window.location.host}${queryString}`
    /* redirect to https */
    window.location.replace(path)
  }
}

export {
  onClientEntry,
}
