import React, { useEffect, useState } from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import { getTenantConfig, deleteSession } from '../services/auth'
import { getTenant, navigateToLogin } from '../util'

const Logout = ({ errorCode, onError, showContentOnError = false, children }) => {
  const [isLoading, setLoading] = useState(true)
  const [cp3LogoutUri, setCp3LogoutUri] = useState()
  const [idpLogoutUri, setIdpLogoutUri] = useState()
  const [relyingPartiesNumber, setRelyingPartiesNumber] = useState(0)
  const [progress, setProgress] = useState(0)
  const [unsuccessfulLogin, setUnsuccessfulLogin] = useState(false)

  const handleFrameLoad = () => setProgress(progress => ++progress)

  const logoutFirebase = async () => {
    try {
      await firebase.auth().signOut()
    } catch (error) {
      console.error('Error signing out Firebase', error)
    }
  }

  useEffect(() => {
    (async () => {
      const tenant = getTenant()
      const { oauthEndSessionEndpoint, hasFederatedLogout } = await getTenantConfig(tenant)
      localStorage.removeItem('session-expiry-timeout')
      await logoutFirebase()
      await deleteSession()
      if (tenant && !window.location.pathname.includes('resetPassword')) {
        const idToken = localStorage.getItem('sso-token')
        localStorage.removeItem('sso-token')
        setCp3LogoutUri(`https://${tenant}.retailcloud.net/authentication_logout.cfm`)
        setRelyingPartiesNumber(1)

        if (hasFederatedLogout && oauthEndSessionEndpoint) {
          setRelyingPartiesNumber(2)
          // OKTA needs this
          const queryString = `?id_token_hint=${idToken}`
          setIdpLogoutUri(`${oauthEndSessionEndpoint}${queryString}`)
        }
      }
    })()
  }, [])

  useEffect(() => {
    if (progress >= relyingPartiesNumber) {
      setLoading(false)

      if (errorCode && typeof onError === 'function') {
        return onError(errorCode)
      }
    }

    if(!window.location.pathname.includes('resetPassword')) {
      setUnsuccessfulLogin(window.location.href.indexOf('unsuccessfulLogin') > -1)
      if(unsuccessfulLogin) navigateToLogin()
    }
  }, [progress, relyingPartiesNumber, errorCode])

  return (
    <div className="logout-container">
      <div className="loader" hidden={!isLoading}></div>
      {(!errorCode || showContentOnError) && !unsuccessfulLogin && children}
      <iframe
        id="cp3-logout"
        title="CP3 Logout page"
        style={{ display: 'none' }}
        src={cp3LogoutUri}
        onLoad={handleFrameLoad}
        onError={handleFrameLoad}
        sandbox="allow-forms allow-scripts"
      />
      <iframe
        id="idp-logout"
        title="IdP Logout page"
        style={{ display: 'none' }}
        src={idpLogoutUri}
        onLoad={handleFrameLoad}
        onError={handleFrameLoad}
        sandbox="allow-forms allow-scripts"
      />
    </div>
  )
}

export default Logout
