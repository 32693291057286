import Cookies from 'js-cookie'
import qs from 'query-string'

const EMAIL_REGEX = /(.+)@(.+){2,}\.(.+){2,}/

const logoutOnErrorCodes = [
  'CP3-001',
  'CP3-002',
  'CP3-003',
  'SSO-001',
  'SSO-002',
  'SSO-003',
  'SESS-001',
  'SESS-002',
  'SESS-003',
]

export const getTenant = () => {
  const domainParts = window.location.hostname.split('.')
  // we get the tenant from the host or from a previously stored cookie if no subdomain is set (one case)
  return domainParts.length > 2 && !domainParts[0].match(/^(one(-\w+)?)$/)
    ? domainParts[0]
    : Cookies.get(process.env.GATSBY_COOKIE_TENANT)
}

export const getErrorFromCode = code => {
  switch (code) {
    case 'LOGIN-001':
      return 'Please enter a valid email address and password.'
    case 'LOGIN-002':
      return 'Too many unsuccessful login attempts. Please try again later.'
    case 'CP3-001': // User not registered on CP3
      return 'This user is not registered, please contact your administrator'
    case 'CP3-002': // Password policy expired
      return 'The password expired, please reset it'
    case 'CP3-003': // IP restriction
      return 'The system can\'t be accessed from your current location, please contact your system administrator'
    case 'SESS-001': // Session expired
      return 'Your session expired'
    case 'SSO-001': // SSO not configured properly
      return 'Your corporate sign in is not set up properly.\n Please contact your system administrator'
    case 'SSO-002':
      return 'An error occurred trying to sign in with your Corporate Account'
    case 'SESS-002': // Session not valid, user profile incomplete or user archived
    case 'SSO-003':  // Verify token failed due to user being archived or missing key info
      return 'We are sorry, but we are not able to log you into Quinyx, as we are missing some key data or your account is not active.\nPlease contact your System Admin'
    case 'SESS-003': // Session not valid, user email doesn't exist
      return 'Sorry we cannot display this content as your account is missing key information or has been modified.\n' +
        '\n' +
        'Please re-enter your login details and try again. If the problem persists, please contact your System Admin.'
    default:
      return 'An error occurred.\n Please contact your system administrator'
  }
}

export const logoutOnError = code => {
  return logoutOnErrorCodes.includes(code)
}

export const base64urlEncode = str => {
  // Convert the ArrayBuffer to string using Uint8 array to convert to what btoa accepts.
  // btoa accepts chars only within ascii 0-255 and base64 encodes them.
  // Then convert the base64 encoded to base64url encoded
  //   (replace + with -, replace / with _, trim trailing =)
  return btoa(String.fromCharCode.apply(null, new Uint8Array(str)))
      .replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

export const navigateToLogin = () => {
  const queryParams = qs.parse(window.location.search) || {}
  const queryString = `${qs.stringify({ ...queryParams, referrer: document.referrer })}`
  const protocol = window.location.hostname === 'localhost' ? window.location.protocol : 'https:'
  window.location.href = `${protocol}//${window.location.host}?${queryString}`
}

export const buildEmail = (username, domain = 'one') =>
  EMAIL_REGEX.test(username)
    ? username
    : `${process.env.GATSBY_AUTH_PREFIX}+${domain}.${username}@${process.env.GATSBY_AUTH_DOMAIN}`