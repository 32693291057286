import React, { useEffect, useState } from 'react'
import Logout from '../components/Logout'
import qs from 'query-string'
import { navigateToLogin } from '../util'

const LogoutPage = () => {
  const [errorCode, setErrorCode] = useState()

  useEffect(() => {
    const { error } = qs.parse(window.location.search) || {}
    setErrorCode(error)
  }, [])

  function redirectOnError(errorCode) {
    window.location.replace(`/errorPage?error=${errorCode}`)
  }

  return (
    <Logout errorCode={errorCode} onError={redirectOnError}>
      <div className="logout-container">
        <div className="logout-message">
          <p>You have been logged out of Quinyx! Please close this window</p>
          <p>or</p>
          <button name="login" className="login-button" onClick={navigateToLogin}>
            Login
          </button>
        </div>
      </div>
    </Logout>
  )
}

export default LogoutPage
