import React, { useEffect, useState } from 'react'
import qs from 'query-string'
import { getErrorFromCode, logoutOnError, navigateToLogin } from '../util'
import Logout from '../components/Logout'

const ErrorPage = () => {
  const [message, setMessage] = useState()
  const [errCode, setErrCode] = useState()

  useEffect(() => {
    const { error } = qs.parse(window.location.search)
    setErrCode(error)
    setMessage(getErrorFromCode(error))
  }, [])

  return (
    <>
      {logoutOnError(errCode) ?
        <Logout errorCode={errCode} showContentOnError={true}>
          <div className="error-container">
            <div className="error-message">
              <p>{message}</p>
            </div>
            <button name="login" className="login-button" onClick={navigateToLogin}>Login</button>
          </div>
        </Logout> :
        <div className="error-container">
          <div className="error-message">
            <p>{message}</p>
          </div>
        </div>
      }
    </>
  )
}

export default ErrorPage
